import * as React from 'react'
import * as css from './404.module.css'
import DefaultLayout from 'layouts/Default'
import { Link } from 'gatsby'
import Search from 'components/common/Search'

function goToPrevPg() {
  window?.history.back()
  return false
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const NotFoundPg = () => (
  <DefaultLayout title="Page not found!" className="container section-padding side-padding">
    <h1 className="h1 text-center">Page not found!</h1>

    <div className={css.grid}>
      <div className={css.num}>404</div>

      <div className="richtext">
        <p>
          We're sorry, but we can't find the page you were looking for.
          It's probably some thing we've done wrong but now we know about it and we'll try to fix it.
          In the meantime, try one of these options:
        </p>
        <ul className={css.list}>
          <li><button className={`${css.btn} link`} onClick={goToPrevPg}>Go to previous page</button></li>
          <li><Link className="link" to="/">Go to Homepage</Link></li>
        </ul>

        <Search placeholder="Search blogs" />
      </div>
    </div>
  </DefaultLayout>
)

export default NotFoundPg
